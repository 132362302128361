<template>
  <div class="app-container">
    <nav>
      <div class="tips">
        <el-button @click="toPath" type="primary">返回</el-button>
      </div>
    </nav>

    <div class="filter-container" v-loading='isLoading'>
        <ul>
            <li v-for="(item,index) in  inspectList" :key="index">
                <p class="title">
                    <span class="text">
                        <i :class="'blue'" class="iconfont iconicon2-23"></i>{{item.name}}
                    </span>
                    <el-button class="btn" @click="update(item)" type="primary">修改</el-button>
                </p>
                <p class="content" v-for="(data,index) in  item.salaryTemplateEntryDtoList" :key="index">
                    {{data.direction == '1'? '借':'贷'}} ：{{data.subFullName}}
                </p>
            </li>
        </ul>
    </div>

    <setting-tips :visible='settingVis' :cancelDialog='cancelSetting' :confirmDialog='confirmSetting' :settingData='settingData' :isdeling='isdeling' />
  </div>
</template>

<script>
  import SettingTips from './modules/dialog/settingTips.vue';
  import axios from "axios";
  export default {
    name: "settings",
    components:{SettingTips,},
    data() {
      return {
        isLoading:false,
        inspectList: [
          { title: '计提工资',  id :'1' , template : '01', groupId: 1,
            list:[
            //  {subId: 10131,
            // subFullName: "管理费用_管理人员职工薪酬",
            // subCode: "5602001",
            // direction: "1",
            // code: "15"}
            ]
          },
          { title: '发放工资', id :'2' , template : '02', groupId: 2,
            list:[
                // { direction:'借',directionId : '1',
                //   subject:'应付职工薪酬',subjectId:'',
                //   value:'',valueId:'1'},
                // { direction:'借',directionId : '1',
                //   subject:'银行存款',subjectId:'',
                //   value:'',valueId:'2'},
                // { direction:'贷',directionId : '2',
                //   subject:'其他应付款',subjectId:'',
                //   value:'',valueId:'3'},
                // { direction:'贷',directionId : '2',
                //   subject:'其他应付款',subjectId:'',
                //   value:'',valueId:'4'},
                // { direction:'贷',directionId : '2',
                //   subject:'其他应付款',subjectId:'',
                //   value:'',valueId:'5'},
                // { direction:'贷',directionId : '2',
                //   subject:'其他应付款',subjectId:'',
                //   value:'',valueId:'6'},
                // { direction:'贷',directionId : '2',
                //   subject:'应交税费-应交个人所得税',subjectId:'',
                //   value:'',valueId:'7'},
            ]
          },
        ],
        isdeling : false,
        settingVis :false,
        settingData : {},
      }
    },
    methods: {
      toPath() {
        this.$router.push('/salary/salaryManage')
      },
      update(item){
        this.settingVis =  true;
        this.settingData = JSON.parse(JSON.stringify(item));
      },
      cancelSetting(){
        this.settingVis =  false;
        this.settingData = {};
      },
      confirmSetting(){
          this.isdeling = true;
        try {
          this.settingData.salaryTemplateEntryDtoList.map((item, index) => {  //未取值判断
            if (!item.subId) {
              throw new Error(`亲，第${index + 1}行，请选择科目`)
            } else if (!item.code) {
              throw new Error(`亲，第${index + 1}行，请选择取值`)
            }
          })
        } catch (error) {
            einvAlert.error("提示",error.message)
            this.isdeling = false;
            return
        }
        let url = '/api/salary/salaryTemplate/update';
        axios.put(url, JSON.stringify(this.settingData), {  //json格式
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          },
        }).then(res => {
          if (res.data.success) {
            this.isdeling = false;
            this.settingVis =  false;
            this.initList();
          } else {
            this.isdeling = false;
            this.settingVis =  false;
            einvAlert.warning("提示",res.data.msg);
          }
        }, err => {
          console.log(err)
        })

      },
      initList() {  //模板数据
        this.isLoading = true
        this.$store.dispatch("GetSalaryTemplateList", {
          asId: this.getToken('asId'),
          period : this.queryTime,
        }).then(res => {
          if (res.success) {
            this.isLoading = false
            console.log(res.data);
            this.inspectList = res.data;
          } else {
            this.isLoading = false
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },
    },
    mounted() {
      this.initList();
    },
  }
</script>

<style lang='less' scoped>
  nav {
    height: 60px;
    display: flex;
    padding: 0 18px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
  }
  .filter-container {
    padding: 1.04rem 1.04rem 60px;
  }
  .filter-container {
    ul {
      margin: 10px auto 0;
      padding: 0;
      flex-wrap: wrap;
      display: flex;
      li {
        position: relative;
        list-style: none;
        width: 49.3%;
        min-height: 186px;
        display: inline-block;
        background: #ffffff;
        border: 1px solid #DCDCDC;
        border-radius: 2px;
        margin-right: 20px;
        vertical-align: top;
        padding: 20px;
        .title {
          font-size: 20px;
          font-family: Microsoft YaHei, Microsoft YaHei-Bold;
          font-weight: 700;
          color: #333333;
          margin-top: 20px;
          display: flex;
          line-height: 30px;
          justify-content: space-between;
          padding: 0;
          margin: 0;
          i{
              margin-right: 10px;
          }
        }
        .content{
          padding: 0 50px 0;
          font-size: 16px;
        }
      }
      li:nth-child(2n) {
        margin-right: 0;
      }
    }
    .yellow {
      color: #FF9700;
      cursor: pointer;
    }
    .blue {
      color: #4f71ff;
      cursor: pointer;
    }
  }
</style>
